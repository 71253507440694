import React, { useEffect, useState } from "react";

function LocationMap() {
  return (
    <div className="main">
      <div className="location">
        <div className="container">
          <div className="location-card">
            <div>
              <h2>location</h2>
              <p>
                <i className="fa fa-location"></i>11800 Sunrise Valley Dr.,
                Suite #250 Reston, VA 20191
              </p>
              <button>Get Directions</button>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.295897448815!2d31.44126937505967!3d29.9996589206922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583cca94ee29cb%3A0x5a00524c5055a8f!2s398%20Street%2023%2C%20New%20Cairo%201%2C%20Cairo%20Governorate%204722624!5e0!3m2!1sen!2seg!4v1720616636567!5m2!1sen!2seg"
              height="300"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationMap;
