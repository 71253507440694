import React, { useEffect, useState } from "react";
import Img1 from "../Assets/Images/Image BG.png";
import Img2 from "../Assets/Images/Image BG (1).png";
import Img3 from "../Assets/Images/Image BG (2).png";
import Img4 from "../Assets/Images/17946370571667326703.svg";
import Img5 from "../Assets/Images/19948932931655372900 1.svg";
import Img6 from "../Assets/Images/9293099951595156175.svg";
import Img7 from "../Assets/Images/3885487101621316687 1.svg";
import Img8 from "../Assets/Images/Rectangle 29848.png";
import Img9 from "../Assets/Images/programmer-and-developer-teamwork-2022-02-10-20-35-17-utc 1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Partner from "./Partner";
import parse from "html-react-parser";

function Home() {
  let [services, setServices] = useState([]);
  const getServices = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/services`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setServices(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getServices();
    // document.body.classList.add('pro-nav');
    document.body.classList.remove("join-nav");
    document.body.classList.remove("active-nav");
  }, []);
  return (
    <div className="home">
      <div className="hero">
        <Swiper
          pagination={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="layout" style={{ backgroundImage: `url(${Img9})` }}>
              <p>We combine design, thinking and technical craft</p>
              <h1>
                HIGHLY CREATIVE <br /> SOLUTION
              </h1>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="layout">
              <p>We combine design, thinking and technical craft</p>
              <h1>
                HIGHLY CREATIVE <br /> SOLUTION
              </h1>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="layout">
              <p>We combine design, thinking and technical craft</p>
              <h1>
                HIGHLY CREATIVE <br /> SOLUTION
              </h1>
            </div>
          </SwiperSlide>
        </Swiper>

        <a href="#Services" className="mouse">
          <span className="mouse-btn">
            <span className="mouse-scroll"></span>
          </span>
        </a>
      </div>

      <div className="services en" id="Services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <h2 className="main-heading">MAIN SERVICES</h2>
                <p className="main-description">
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus mollis sit aliquam sit nullam.
                </p>
              </div>
            </div>
            {services?.map((x, index) => (
              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <img src={x.image} className="card-img-top" alt="card-img" />
                  <div className="card-body">
                    <div className="">
                      <h3 className="card-title">{x.name}</h3>
                      <p className="card-text">{parse(x.brief)}</p>
                    </div>
                    <Link to="/Services" className="">
                      View more <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="about en">
        <div className="container">
          <div className="about-card">
            <div className="about-layout">
              <div className="about-tools">
                <h2>About Dentotools</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus consectetur adipiscing eli mattis sit phasellus
                  mollis sit aliquam sit nullam neque ultrices.
                </p>
                <Link to="/About">
                  Learn More <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lab en">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="main-heading">
                Numbers that showcase our success
              </h2>
              <p className="main-description">
                Lorem ipsum dolor sit amet consectetur nunc nunc sit velit eget
                sollicitudin sit posuere augue vestibulum eget turpis lobortis
                donec sapien integer.
              </p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-12 mb-lg-0 mb-4">
                  <div className="dental-lab">
                    <div className="dental-layout">
                      <h3>200+</h3>
                      <span>Dental Lab</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="row">
                    <div className="col-md-6 mb-md-4">
                      <div className="number-showcase">
                        <img src={Img4} alt="Doctor" />
                        <div className="">
                          <h3>200+</h3>
                          <span>Doctor</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="number-showcase">
                        <img src={Img5} alt="Client" />
                        <div className="">
                          <h3>98%</h3>
                          <span>Client satisfaction</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="number-showcase">
                        <img src={Img6} alt="Student" />
                        <div className="">
                          <h3>30K+</h3>
                          <span>Student</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="number-showcase">
                        <img src={Img7} alt="Clinic" />
                        <div className="">
                          <h3>100+</h3>
                          <span>Clinic</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Partner />

      <div className="contact en">
        <div className="container">
          <div className="contact-card">
            <div className="contact-layout">
              <h2>
                For any questions or concerns <br /> don't hesitate to contact
                us.
              </h2>
              <Link to="/Contact" className="">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
