// import { useContext, createContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
//   const navigate = useNavigate();

//   const loginAction = async (data) => {
//     try {
//       const response = await fetch(
//         "https://dentotools.addictaco.com/api/v1/clients/auth/login",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             "x-api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
//             "Accept-Language": "en",
//             "device-type": "Web",
//             "device-id": "1234568",
//           },
//           body: JSON.stringify(data),
//         }
//       );
//       const res = await response.json();
//         console.log(res);

//       if (res) {
//         setUser(res.refreshToken);
//         setToken(res.token);
//         localStorage.setItem("accessToken", res.token);
//         localStorage.setItem("refreshToken", res.refreshToken);
//         navigate("/Home");
//         return;
//       }
//       throw new Error(res.message);
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const auth = useAuth();
//   const logOut = async (data) => {
//     try {
//       const response = await fetch(
//         "https://dentotools.addictaco.com/api/v1/clients/auth/logout",
//         {
//           method: "GET",
//           headers: {
//             Authorization: "Bearer " + auth.token,
//             "Content-Type": "application/json",
//             "x-api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
//           },
//         }
//       );
//       if (response) {
//         setUser(null);
//         setToken("");
//         localStorage.clear();
//         navigate("/");
//         return;
//       }
//       throw new Error(response.message);
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   return (
//     <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;

// export const useAuth = () => {
//   return useContext(AuthContext);
// };













import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();
  
  const loginAction = async (data) => {
    try {
      const response = await fetch(
        "https://dentotools.addictaco.com/api/v1/clients/auth/login",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            // "device-token":
            //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvYXBpL3YxL3RlYWNoZXIvYXV0aC9sb2dpbiIsImlhdCI6MTcxNzA3OTc3NywibmJmIjoxNzE3MDc5Nzc3LCJqdGkiOiJmRWRzUjMyYlNCa2pwTFpEIiwic3ViIjoiM2MxNTI4ODQtODAzMS00Zjk3LWI3MDMtMTljMTY5MDU4ZWFjIiwicHJ2IjoiODFjMGJjYmVlMjMxOGI5MWE4M2U2MDJjOGI5OTRlYmFlMTNmNmY3MyJ9.npevjPT7oGfc-OBItgbtBIQD6FJw5XE5Jx3pXN_l9hk",
            "device-type": "Web",
            "Content-Type": "application/json",
            "device-id": "123456",
          },
          body: JSON.stringify(data),
        }
      );
      const res = await response.json();
      if (res) {
        setUser(res.data.refreshToken);
        setToken(res.data.token);
        localStorage.setItem("accessToken", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        navigate("/");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };


  const auth = useAuth();
  const logOut = async (data) => {
    try {
      const response = await fetch(
        "https://dentotools.addictaco.com/api/v1/clients/auth/logout",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        setUser(null);
        setToken("");
        localStorage.clear();
        navigate("/");
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{ token, user, loginAction, logOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
