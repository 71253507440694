// import React, { useEffect, useLayoutEffect, useState } from "react";
// import Img8 from "../Assets/Images/Basemap image.png";
// import Img9 from "../Assets/Images/Basemap image (1).png";
// import Img1 from "../Assets/Images/Rectangle 145.png";
// import Img2 from "../Assets/Images/Ellipse 798.svg";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import { Navigation } from "swiper/modules";
// import { Pagination } from "swiper/modules";
// import { Autoplay } from "swiper/modules";
// import { useTranslation } from "react-i18next";
// import "../translations/i18n";
// import SUBSERVICES from "../Data/SUBSERVICES";
// import Labs from "../Data/Labs";
// import { Link } from "react-router-dom";
// import MasterForm from "../Steps/MasterForm";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Reviews from "./reviews";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { useParams } from "react-router";
// import LocationMap from "./location";
// import parse from "html-react-parser";
// import { useAuth } from "../logIn/AuthProvider";

// function Labs_details() {
//   let [labs, setLabs] = useState([]);
//   let [subOther, setSubOther] = useState([]);
//   let [subLabs, setSubLabs] = useState([]);
//   const { id } = useParams();
//   console.log(id);
//   const [show, setShow] = useState(false);
//   const auth = useAuth();

//   const [loading, setLoading] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const getLabs = async () => {
//     try {
//       setLoading(true)
//       const response = await axios.get(
//         `https://dentotools.addictaco.com/api/v1/labs/${id}`,
//         {
//           headers: {
//             Accept: ["application/json", "application/json"],
//             "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
//             "Accept-Language": "en",
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       setLoading(false)
//       console.log(response);
//       setLabs(response?.data?.data);
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Something went wrong!",
//       });
//     }
//   };



// console.log(labs);
// console.log(labs?.sub_services[0]);

//   useLayoutEffect(() => {
//     getLabs();
//   }, [id]);
//   console.log("labs.id", labs.id);
//   // console.log("lab_service_variation_id", labs.sub_services[0].variations[0].sub_sub_service_id);
//   // console.log(
//     //   "lab_sub_sub_service_id",
//     //   labs.lab_sub_sub_services[0].sub_sub_service_id
//   // );
//   // console.log(
//   //   "lab_sub_sub_service_variation_id",
//   //   labs.lab_sub_sub_services[0].lab_sub_sub_service_variations[0].lab_sub_sub_service_id
//   // );
//   const getSubLabs = async () => {
//     try {
//       const response = await axios.get(
//         `https://dentotools.addictaco.com/api/v1/sub-services/${id}/labs`,
//         {
//           headers: {
//             Accept: ["application/json", "application/json"],
//             "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
//             "Accept-Language": "en",
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       // setLabs(response.data.data);
//       setSubLabs(response?.data?.data);
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Something went wrong!",
//       });
//     }
//   };
//   const getSubOther = async () => {
//     try {
//       const response = await axios.get(
//         `https://dentotools.addictaco.com/api/v1/sub-services/${id}/other`,
//         {
//           headers: {
//             Accept: ["application/json", "application/json"],
//             "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
//             "Accept-Language": "en",
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       // setLabs(response.data.data);
//       setSubOther(response?.data?.data);
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Something went wrong!",
//       });
//     }
//   };
//   // useEffect(() => { 
//   //   getSubLabs();
//   //   getSubOther();
//   //   // document.body.classList.add('pro-nav');
//   //   document.body.classList.remove("join-nav");
//   //   document.body.classList.add("active-nav");
//   // }, []);

  


//   if (loading) {
//     return <div>Loading...</div>;  // Show loading spinner or message
//   }
//   // console.log("lab_service_id", labs?.sub_services[0]);
//   return (
//     <div className="main">
//       <div className="single-lab-details">
//         <div className="">
//           <Swiper
//             slidesPerView={1}
//             spaceBetween={10}
//             loop={true}
//             modules={[Autoplay]}
//             centeredSlides={true}
//             autoplay={{
//               delay: 2500,
//               disableOnInteraction: false,
//             }}
//             breakpoints={{
//               440: {
//                 slidesPerView: 1,
//                 spaceBetween: 20,
//               },
//               768: {
//                 slidesPerView: 2,
//                 spaceBetween: 30,
//               },
//               1024: {
//                 slidesPerView: 3,
//                 spaceBetween: 30,
//               },
//             }}
//             className="mySwiper"
//           >
//             {labs?.images?.length > 0 ? (
//               labs?.images?.map((x, index) => (
//                 <SwiperSlide key={index}>
//                   <div className="h-100">
//                     <img
//                       src={`http://dentotools.addictaco.com/storage/${x}`}
//                       alt="lab-logo"
//                     />
//                     <img src={Img9} className="img-layout" alt="lab-logo" />
//                   </div>
//                 </SwiperSlide>
//               ))
//             ) : (
//               <p>No tools available</p>
//             )}
//           </Swiper>
//         </div>
//       </div>
//       <div className="lab-details-logo en" key={labs?.id}>
//         <div className="container">
//           <div className="d-flex align-items-center">
//             <img
//               src={`http://dentotools.addictaco.com/storage/${labs?.logo}`}
//               alt="lab-logo"
//             />
//             <div>
//               <div>
//                 <ul>
//                   <li>
//                     <i className="fa fa-star fa-yellow"></i>
//                   </li>
//                   {labs?.rate}
//                 </ul>
//               </div>
//               <h2>{labs?.name}</h2>
//               <span>8 Services</span>
//             </div>
//           </div>
//           <p>{parse(`${labs?.brief}`)}</p>
//           <div className="order">
//             {!auth.token ? (
//               <Link to="/SignIn" className="profile-button">
//                 <i className="fa fa-user"></i>Login
//               </Link>
//             ) : (
//               <button onClick={handleShow}>Order Now</button>
//             )}

//             {/* <div>
//               <h3>cost range</h3>
//               <strong>
//                 {labs?.sub_services?.min_cost} ~ {labs?.sub_services?.max_cost}{" "}
//                 EGP
//               </strong>
//             </div> */}
//           </div>
//         </div>
//       </div>
//       <div className="SUBSERVICES en">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 mb-5">
//               <h2 className="main-heading en">SUB SERVICES </h2>
//             </div>
//             {subLabs.map((x, index) => (
//               <Link to="/services" className="col-lg-4 col-md-6" key={index}>
//                 <div className="card-SUBSERVICES en">
//                   <div>
//                     <img src={x.logo} alt="lab" />
//                     <span>{x.name}</span>
//                   </div>
//                   <p>{x.sub_services_count} Lab</p>
//                 </div>
//               </Link>
//             ))}
//           </div>
//         </div>
//       </div>
//       <div className="SUBSERVICES en">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 mb-5">
//               <h2 className="main-heading en">Other SUBSERVICES </h2>
//             </div>
//             {subOther.map((x, index) => (
//               <Link to="/services" className="col-lg-4 col-md-6" key={index}>
//                 <div className="card-SUBSERVICES en">
//                   <div>
//                     <img src={x.image} alt="lab" />
//                     <span>{x.name}</span>
//                   </div>
//                   <p>{x.labs_count} Lab</p>
//                 </div>
//               </Link>
//             ))}
//           </div>
//         </div>
//       </div>
//       <LocationMap />
//       {/* <Reviews /> */}
// {labs.id}
//       <Modal
//         show={show}
//         size="lg"
//         onHide={handleClose}
//         className="crown-tabs"
//         aria-labelledby="example-modal-sizes-title-lg"
//       >
//         <Modal.Body>
//           {/* <Tabs> */}
//           <h4>I Want to order ..</h4>
//           <div className="">
//             <div className="container">
//               <MasterForm />
//             </div>
//           </div>
//           {/* </Tabs> */}
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// }

// export default Labs_details;



import React, { useEffect, useLayoutEffect, useState } from "react";
import Img9 from "../Assets/Images/Basemap image (1).png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../logIn/AuthProvider";
import axios from "axios";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";
import MasterForm from "../Steps/MasterForm";
import LocationMap from "./location";

function LabsDetails() {
  const [labs, setLabs] = useState(null);
  const [subOther, setSubOther] = useState([]);
  const [subLabs, setSubLabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const auth = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Fetch Lab details
  const getLabs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/labs/${id}`,
        {
          headers: {
            Accept: "application/json",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Content-Type": "application/json",
          },
        }
      );
      setLabs(response?.data?.data || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  // Fetch SubLabs
  const getSubLabs = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/sub-services/${id}/labs`,
        {
          headers: {
            Accept: "application/json",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Content-Type": "application/json",
          },
        }
      );
      setSubLabs(response?.data?.data || []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  

  // Fetch Other SubServices
  const getSubOther = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/sub-services/${id}/other`,
        {
          headers: {
            Accept: "application/json",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Content-Type": "application/json",
          },
        }
      );
      setSubOther(response?.data?.data || []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getLabs();
    getSubLabs();
    getSubOther();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>; // Show loading spinner or message
  }
// console.log(labs);

//   // console.log("labs.id", labs.id);
//   console.log("lab_service_variation_id", labs.sub_services[0].variations[0].sub_sub_service_id);
//   console.log(
//       "lab_sub_sub_service_id",
//       labs.lab_sub_sub_services[0].sub_sub_service_id
//   );
//   console.log(
//     "lab_sub_sub_service_variation_id",
//     labs.lab_sub_sub_services[0].lab_sub_sub_service_variations[0].lab_sub_sub_service_id
//   );
  return (
    <div className="main">
      <div className="single-lab-details">
        <div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            modules={[Autoplay]}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            className="mySwiper"
          >
            {labs?.images?.length > 0 ? (
              labs.images.map((x, index) => (
                <SwiperSlide key={index}>
                  <div className="h-100">
                    <img
                      src={`ttp://dentotools.addictaco.com/storage/${x}`}
                      alt="lab-logo"
                    />
                    <img src={Img9} className="img-layout" alt="lab-layout" />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <p>No tools available</p>
            )}
          </Swiper>
        </div>
      </div>

      <div className="lab-details-logo en" key={labs?.id}>
        <div className="container">
          <div className="d-flex align-items-center">
            <img
              src={`http://dentotools.addictaco.com/storage/${labs?.logo}`}
              alt="lab-logo"
            />
            <div>
              <ul>
                <li>
                  <i className="fa fa-star fa-yellow"></i>
                </li>
                {labs?.rate}
              </ul>
              <h2>{labs?.name}</h2>
              <span>8 Services</span>
            </div>
          </div>
          <p>{parse(labs?.brief || "")}</p>
          <div className="order">
            {!auth.token ? (
              <Link to="/SignIn" className="profile-button">
                <i className="fa fa-user"></i>Login
              </Link>
            ) : (
              <button onClick={handleShow}>Order Now</button>
            )}
          </div>
        </div>
      </div>

      <div className="SUBSERVICES en">
        <div className="container">
          <h2 className="main-heading en">SUB SERVICES </h2>
          <div className="row">
            {subLabs.length > 0 ? (
              subLabs.map((x, index) => (
                <Link to="/services" className="col-lg-4 col-md-6" key={index}>
                  <div className="card-SUBSERVICES en">
                    <img src={x.logo} alt="lab" />
                    <span>{x.name}</span>
                    <p>{x.sub_services_count} Lab</p>
                  </div>
                </Link>
              ))
            ) : (
              <p>No SubLabs available</p>
            )}
          </div>
        </div>
      </div>

      <div className="SUBSERVICES en">
        <div className="container">
          <h2 className="main-heading en">Other SUBSERVICES </h2>
          <div className="row">
            {subOther.length > 0 ? (
              subOther.map((x, index) => (
                <Link to="/services" className="col-lg-4 col-md-6" key={index}>
                  <div className="card-SUBSERVICES en">
                    <img src={x.image} alt="lab" />
                    <span>{x.name}</span>
                    <p>{x.labs_count} Lab</p>
                  </div>
                </Link>
              ))
            ) : (
              <p>No Other SubServices available</p>
            )}
          </div>
        </div>
      </div>

      <LocationMap />

      <Modal show={show} size="lg" onHide={handleClose} className="crown-tabs">
        <Modal.Body>
          <h4>I Want to order ..</h4>
          <div className="container">
            <MasterForm lab={labs} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LabsDetails;
