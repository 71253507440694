import React, { useEffect, useState } from "react";
import { Form, Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import Background from '../Assets/Images/a387d798cd.jpg';
import axios from "axios";
import Swal from "sweetalert2";

function Contact() {
    const { t } = useTranslation();
  
    const handleSubmit = (event) => {
      event.preventDefault();
    };
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };

    
    useEffect(() => {
        // document.body.classList.add('pro-nav');
        document.body.classList.add ('active-nav');
    }, []);


  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
  });


  const Contact = async (e) => {
    e.preventDefault();
    const response = await axios
      .post("https://dentotools.addictaco.com/api/v1/contact-us", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
          "Accept-Language": "en",
          "device-type": "Web",
          "device-id": "123456",
        },
      })
      .then((res) => {
        
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <div className="main">
      <div
        className="hero-services"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <h1>Contact Us</h1>
      </div>
      <div className="layout-contact en">
        <div className="container">
          <div className="contact-us">
            <div className="row">
              <div className="col-md-5">
                <div className="card-contact">
                  <div>
                    <h1>Contact Information</h1>
                    <p>
                      Fill up the form and our Team will get back to you within
                      24 hours.
                    </p>
                  </div>
                  <div>
                    <a className="" href="tel:+01000000000">
                      <i className="fa fa-phone"></i>+01000000000
                    </a>
                    <a className="" href="mailto:demo@gmail.com">
                      <i className="fa fa-envelope"></i>demo@gmail.com
                    </a>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a className="">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a className="">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <Form onSubmit={handleSubmit} className="form-contact">
                  <>
                    <Form.Group controlId="formStep1" className="form-group">
                      <Form.Label>{t("FullName")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="full_name"
                        placeholder={t("FullName_placeholder")}
                        value={formData.full_name}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formStep1" className="form-group">
                      <Form.Label>{t("Email")}</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder={t("Email_placeholder")}
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formStep1" className="form-group">
                      <Form.Label>{t("phone")}</Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        placeholder={t("phone_placeholder")}
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="formStep1" className="form-group">
                      <Form.Label>{t("Message")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder={t("Write_message")}
                        style={{ height: "100px" }}
                      />
                    </Form.Group>
                  </>
                  <div className="d-flex justify-content-between">
                    <button
                      className="Continue mt-5"
                      type="submit"
                      onClick={Contact}
                    >
                      {t("Send_Message")}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
