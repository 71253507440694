import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useAuth } from "../logIn/AuthProvider";
import Background from '../Assets/Images/28ce2209a5.jpg';
import Count from "./count";

function Sterilization() {
    const [loading, setLoading] = useState(false);
    const [tools, setTools] = useState([]);  // Store the list of tools
    const [formData, setFormData] = useState([]);  // Store the array of objects to send
    const auth = useAuth();

    // Fetch the list of tools from the API
    const getTool = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `https://dentotools.addictaco.com/api/v1/tools`,
                {
                    headers: {
                        "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
                        "Accept-Language": "en",
                        "Content-Type": "application/json",
                    },
                }
            );
            setTools(response?.data);
            setFormData(response?.data.map(tool => ({
                tool_id: tool.id, 
                number_of_pieces: 0  // Set the initial number_of_pieces to 1
            })));  // Initialize formData with tool_id and number_of_pieces = 1
            setLoading(false);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        getTool();  // Fetch tools on component mount
    }, []);

    // Update formData with the correct tool_id and number_of_pieces
    const decr = (tool_id, newPieces) => {
        console.log(newPieces);
        setFormData(prevFormData => {
            const updatedFormData = prevFormData.map(tool => 
                tool.tool_id === tool_id 
                ? { ...tool, number_of_pieces:newPieces}  // Ensure number_of_pieces is at least 1
                : tool  // Keep other tools unchanged
            );
            return updatedFormData;
        });
    };

    // Send the sterilization request to the API
    const sendRequests = async () => {
        try {
            setLoading(true);
            
            // Filter out tools with number_of_pieces set to 0
            const filteredFormData = formData.filter(tool => tool.number_of_pieces > 0);

            // Prepare the payload
            const payload = {
                tools: filteredFormData.map(tool => ({
                    tool_id: tool.tool_id,
                    number_of_pieces: tool.number_of_pieces
                }))
            };

            const response = await axios.post(
                "https://dentotools.addictaco.com/api/v1/orders/sterilization-requests",
                JSON.stringify(payload),  // Convert payload to JSON
                {
                    headers: {
                        Authorization: "Bearer " + auth.token,
                        "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
                        "Accept-Language": "en",
                        "Content-Type": "application/json",
                    },
                }
            );

            Swal.fire({
                icon: "success",
                title: "Request Sent!",
                text: "Your sterilization request has been submitted.",
            });

            setLoading(false);
            // window.location.reload()
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="main">
                <div className="search-services" style={{ backgroundImage: `url(${Background})` }}>
                    <h1>Sterilization</h1>
                </div>

                <div className="container">
                    <div className="count-card">
                        <div className="counter-card">
                            <div className="row">
                                {tools.length > 0 ? (
                                    tools.map((tool, index) => (
                                        <div className="col-md-3" key={index}>
                                            <Count 
                                                de={decr} 
                                                path={tool} 
                                                indx={index} 
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>No tools available</p>
                                )}
                            </div>
                        </div>
                        <div className="text-end mt-5 mb-5">
                            {!auth.token ? (
                                <Link to="/SignIn" className="profile-button">
                                    <i className="fa fa-user"></i>Login
                                </Link>
                            ) : (
                                <Link onClick={sendRequests} className="continue">
                                    Check out
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sterilization;
