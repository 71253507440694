import React, { useEffect, useState } from "react";
import Img8 from "../Assets/Images/1.png";
import Img9 from "../Assets/Images/Basemap image (1).png";
import Img1 from "../Assets/Images/2.png";
import Img2 from "../Assets/Images/Ellipse 798.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import SUBSERVICES from "../Data/SUBSERVICES";
import Labs from "../Data/Labs";
import { Link } from "react-router-dom";
import MasterForm from "../Steps/MasterForm";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import img from "../Assets/Images/WhatsApp Image 2024-09-01 at 1.49.06 PM.jpeg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import LocationMap from "./location";
import parse from "html-react-parser";
import { useAuth } from "../logIn/AuthProvider";

function Freelance_details() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [count, setCount] = useState(0);
  const [Rubber, setRubber] = useState(0);
  const [Burs, setBurs] = useState(0);
  const [Mirrors, setMirrors] = useState(0);
  const [Metal, setMetal] = useState(0);
  const result_Control = Math.ceil(count / 2);
  const result_Rubber = Math.ceil(Rubber / 2);
  const result_Burs = Math.ceil(Burs / 2);
  const result_Mirrors = Math.ceil(Mirrors / 2);
  const result_Metal = Math.ceil(Metal / 2);
  const result =
    result_Control +
    result_Rubber +
    result_Burs +
    result_Mirrors +
    result_Metal;

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count == 0) {
      return false;
    } else {
      setCount(count - 1);
    }
  };
  const incrementRubber = () => {
    setRubber(Rubber + 1);
  };

  const decrementRubber = () => {
    if (Rubber == 0) {
      return false;
    } else {
      setRubber(Rubber - 1);
    }
  };
  const incrementBurs = () => {
    setBurs(Burs + 1);
  };

  const decrementBurs = () => {
    if (Burs == 0) {
      return false;
    } else {
      setBurs(Burs - 1);
    }
  };
  const incrementMirrors = () => {
    setMirrors(Mirrors + 1);
  };

  const decrementMirrors = () => {
    if (Mirrors == 0) {
      return false;
    } else {
      setMirrors(Mirrors - 1);
    }
  };
  const incrementMetal = () => {
    setMetal(Metal + 1);
  };

  const decrementMetal = () => {
    if (Metal == 0) {
      return false;
    } else {
      setMetal(Metal - 1);
    }
  };


  const { id } = useParams();

  let [freelancer, setFreelancer] = useState([]);
  const getFreelancer = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/freelancers/${id}`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setFreelancer(response?.data?.data);
      console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getFreelancer();
    // document.body.classList.add('pro-nav');
    document.body.classList.remove("join-nav");
    document.body.classList.add("active-nav");
  }, []);


  const auth = useAuth();

  const [formData, setFormData] = useState({
    freelancer_id: id,
  });

  const freelancerRequests = async (e) => {
    e.preventDefault();
    const response = await axios
      .post(
        "https://dentotools.addictaco.com/api/v1/orders/freelancer-requests",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "device-type": "Web",
            "device-id": "123456",
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };



  return (
    <div className="main">
      <div className="single-lab-details">
        <div className="">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            modules={[Autoplay]}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              440: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="h-100">
                <img src={Img8} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img1} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img8} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img1} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img8} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img1} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img8} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img1} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-100">
                <img src={Img8} alt="lab-logo" />
                <img src={Img9} className="img-layout" alt="lab-logo" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="lab-details-logo en">
        <div className="container">
          <div className="d-flex align-items-center">
            <img
              src={`http://dentotools.addictaco.com/storage/${freelancer.image}`}
              alt="lab-logo"
            />
            <div>
              <div>
                <ul>
                  <li>
                    <i className="fa fa-star fa-yellow"></i>
                  </li>
                </ul>
              </div>
              <h2>{freelancer?.name}</h2>
              {/* <span>{freelancer?.} Services</span> */}
            </div>
          </div>
          <p>{parse(`${freelancer?.description}`)}</p>
          <div className="order">
            <button onClick={freelancerRequests}>Contact</button>
            <div>
              {/* <h3>cost range</h3> */}
              {/* <strong>250 ~ 400 EGP</strong> */}
            </div>
          </div>
        </div>
      </div>
      <div className="location">
        <div className="container">
          <div className="location-card">
            <div>
              <h2>location</h2>
              <p>
                <i className="fa fa-location"></i>11800 Sunrise Valley Dr.,
                Suite #250 Reston, VA 20191
              </p>
              <button>Get Directions</button>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.295897448815!2d31.44126937505967!3d29.9996589206922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583cca94ee29cb%3A0x5a00524c5055a8f!2s398%20Street%2023%2C%20New%20Cairo%201%2C%20Cairo%20Governorate%204722624!5e0!3m2!1sen!2seg!4v1720616636567!5m2!1sen!2seg"
              height="300"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="review">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h1>Reviews</h1>
            <div>
              <a className="review-next">
                <i className="fa fa-arrow-left"></i>
              </a>
              <a className="review-prev">
                <i className="fa fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            modules={[Autoplay, Navigation]}
            navigation={{
              nextEl: ".review-next",
              prevEl: ".review-prev",
            }}
            // autoplay={{
            //     delay: 2500,
            //     disableOnInteraction: false,
            // }}
            breakpoints={{
              440: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="view">
                <h2>Perspiciatis esse molestiae vel qui.</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus consectetur adipiscing eli mattis sit phasellus
                  mollis
                </p>
                <span>Roxanne</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="view">
                <h2>Perspiciatis esse molestiae vel qui.</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus consectetur adipiscing eli mattis sit phasellus
                  mollis
                </p>
                <span>Roxanne</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="view">
                <h2>Perspiciatis esse molestiae vel qui.</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus consectetur adipiscing eli mattis sit phasellus
                  mollis
                </p>
                <span>Roxanne</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="view">
                <h2>Perspiciatis esse molestiae vel qui.</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus consectetur adipiscing eli mattis sit phasellus
                  mollis
                </p>
                <span>Roxanne</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="view">
                <h2>Perspiciatis esse molestiae vel qui.</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus consectetur adipiscing eli mattis sit phasellus
                  mollis
                </p>
                <span>Roxanne</span>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="crown-tabs"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <Tabs>
            <h4>I Want to order ..</h4>
            <TabList className="">
              <Tab>
                <div className="crown">
                  <h3>Sterilization</h3>
                </div>
              </Tab>
              <Tab>
                <div className="crown">
                  <h3>Crown</h3>
                </div>
              </Tab>
            </TabList>

            <div className="">
              <div className="container">
                <TabPanel>
                  <div className="count-card">
                    <h5>
                      Total Pouches <span>{result}</span>
                    </h5>
                    <div className="counter-card">
                      <div className="count">
                        <div className="num-of-counter">
                          <span className={count == 0 ? "disable" : ""}>
                            {result_Control}
                          </span>
                        </div>
                        <div className="price">
                          <h3>Control</h3>
                          <p>9 $</p>
                        </div>
                        <div className="counter">
                          <button
                            onClick={decrementCount}
                            className={count == 0 ? "disable" : ""}
                          >
                            -
                          </button>
                          <span className={count == 0 ? "disable" : ""}>
                            {count}
                          </span>
                          <button onClick={incrementCount}>+</button>
                        </div>
                      </div>
                      <div className="count">
                        <div className="num-of-counter">
                          <span className={Rubber == 0 ? "disable" : ""}>
                            {result_Rubber}
                          </span>
                        </div>
                        <div className="price">
                          <h3>Rubber Dam</h3>
                          <p>7 $</p>
                        </div>
                        <div className="counter">
                          <button
                            onClick={decrementRubber}
                            className={Rubber == 0 ? "disable" : ""}
                          >
                            -
                          </button>
                          <span className={Rubber == 0 ? "disable" : ""}>
                            {Rubber}
                          </span>
                          <button onClick={incrementRubber}>+</button>
                        </div>
                      </div>
                      <div className="count">
                        <div className="num-of-counter">
                          <span className={Burs == 0 ? "disable" : ""}>
                            {result_Burs}
                          </span>
                        </div>
                        <div className="price">
                          <h3>Burs</h3>
                          <p>2 $</p>
                        </div>
                        <div className="counter">
                          <button
                            onClick={decrementBurs}
                            className={Burs == 0 ? "disable" : ""}
                          >
                            -
                          </button>
                          <span className={Burs == 0 ? "disable" : ""}>
                            {Burs}
                          </span>
                          <button onClick={incrementBurs}>+</button>
                        </div>
                      </div>
                      <div className="count">
                        <div className="num-of-counter">
                          <span className={Mirrors == 0 ? "disable" : ""}>
                            {result_Mirrors}
                          </span>
                        </div>
                        <div className="price">
                          <h3>Mirrors</h3>
                          <p>9 $</p>
                        </div>
                        <div className="counter">
                          <button
                            onClick={decrementMirrors}
                            className={Mirrors == 0 ? "disable" : ""}
                          >
                            -
                          </button>
                          <span className={Mirrors == 0 ? "disable" : ""}>
                            {Mirrors}
                          </span>
                          <button onClick={incrementMirrors}>+</button>
                        </div>
                      </div>
                      <div className="count">
                        <div className="num-of-counter">
                          <span className={Metal == 0 ? "disable" : ""}>
                            {result_Metal}
                          </span>
                        </div>
                        <div className="price">
                          <h3>Metal instr</h3>
                          <p>9 $</p>
                        </div>
                        <div className="counter">
                          <button
                            onClick={decrementMetal}
                            className={Metal == 0 ? "disable" : ""}
                          >
                            -
                          </button>
                          <span className={Metal == 0 ? "disable" : ""}>
                            {Metal}
                          </span>
                          <button onClick={incrementMetal}>+</button>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-5 mb-5">
                      <Link to="/Payment" className="continue">
                        Check out
                      </Link>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <MasterForm />
                </TabPanel>
              </div>
            </div>
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Freelance_details;
