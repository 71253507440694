import React, { useState } from "react";

function Count({ path, de, indx }) {
    const [tool] = useState(path);
    const [count, setCount] = useState(0);
    const result = Math.ceil(count / tool?.number_of_pieces);

    const increment = () => {
        console.log("object");
        setCount(prevCount => {
            const newCount = prevCount + 1;
            de(tool.id, Math.ceil(newCount / tool?.number_of_pieces)) // Pass tool id and the updated result
            return newCount;
        });
    };

    const decrement = () => {
        if (count > 0) {
            setCount(prevCount => {
                const newCount = prevCount - 1;
                de(tool.id, Math.ceil(newCount / tool?.number_of_pieces));  // Update the decr function with the new count
                return newCount;
            });
        }
    };

    return (
        <>
            <div className="count">
                <div className="num-of-counter">
                    <span>{result}</span>
                </div>
                <div className="price">
                    <h3>{tool?.tool}</h3>
                    <p>{tool?.price} $</p>
                </div>
                <div className="counter">
                    <button onClick={decrement} className={count === 0 ? "disable" : ""}>-</button>
                    <span className={count === 0 ? "disable" : ""}>{count}</span>
                    <button onClick={increment}>+</button>
                </div>
            </div>
        </>
    );
}

export default Count;
