import React, { useEffect, useState } from "react";
import Img1 from "../Assets/Images/Rectangle 29847.png";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import Labs from "../Data/Labs";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Form, Button, ProgressBar } from 'react-bootstrap';
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../logIn/AuthProvider";

function Profile() {
  const { t, i18n } = useTranslation();
  const [me, setMe] = useState();
  const [order, setOrder] = useState([]);
  const [lab, setLab] = useState([]);
  const [formData, setFormData] = useState({});
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const auth = useAuth();

  

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission
  };
  const logOut = async (data) => {
    try {
      const response = await fetch(
        "https://dentotools.addictaco.com/api/v1/clients/auth/logout",
        {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Accept-Language": "en",
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            // "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        // setUser(null);
        // setToken("");
        localStorage.clear();
        // navigate("/");
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
    }
  };

  
  const getMe = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/clients/auth/profile`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setMe(response?.data?.data);
      console.log(response);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  
  const getOrder = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/orders/client/orders/history`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setOrder(response?.data?.orders);
      console.log(response?.data?.orders);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  
  const getLabs = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/orders/client/labs/history`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            Authorization: "Bearer " + auth.token,
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setLab(response?.data?.labs);
      console.log(response?.data.labs);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  
  useEffect(() => {
    getMe()
    getOrder()
    getLabs()
    // document.body.classList.add('pro-nav');
    document.body.classList.remove ('join-nav');
    document.body.classList.add ('active-nav');
}, []);



  return (
    <div className="main">
      <div className="profile en">
        <Tabs>
          <TabList className="side-bar">
            <Tab>
              <h2>
                <i className="fa fa-user"></i> Profile
              </h2>
            </Tab>
            <Tab>
              <h2>
                <i className="fa fa-clock"></i> History
              </h2>
            </Tab>
            <Tab>
              <h2>
                <i className="fa fa-flask"></i> My Labs
              </h2>
            </Tab>
              <h2>
              <i className="fa fa-right-from-bracket"></i> <Link to="/SignIn" onClick={logOut}>Logout</Link>
              </h2>
          </TabList>

          <div className="my-profile">
            <div className="container">
            <TabPanel>
              <div className="profile-tab">
                <h2>My Profile</h2>
                <div className="card-profile">
                  <h3>Personal Info</h3>
                    <Form onSubmit={handleSubmit} >
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <Form.Group controlId="formStep1" className="form-group">
                              <Form.Label>{t("FullName")}</Form.Label>
                              <Form.Control
                                  type="text"
                                  name="FullName"
                                  placeholder={t("FullName_placeholder")}
                                  value={me?.name}
                                  onChange={handleInputChange}
                              />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <Form.Group controlId="formStep1" className="form-group">
                              <Form.Label>{t("Email")}</Form.Label>
                              <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder={t("Email_placeholder")}
                                  value={me?.email}
                                  onChange={handleInputChange}
                              />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <Form.Group controlId="formStep1" className="form-group">
                              <Form.Label>{t("phone")}</Form.Label>
                              <Form.Control
                                  type="number"
                                  name="phone"
                                  placeholder={t("phone_placeholder")}
                                  value={me?.phone_number}
                                  onChange={handleInputChange}
                              />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <Form.Group controlId="formStep1" className="form-group">
                              <Form.Label>{t("University")}</Form.Label>
                              <Form.Control
                                  type="text"
                                  name="University"
                                  placeholder={t("")}
                                  value={me?.university}
                                  onChange={handleInputChange}
                              />
                          </Form.Group>
                        </div>
                        {me?.user_type == "student" ? "" : <div className="col-lg-4 col-md-6">
                          <Form.Group controlId="formStep1" className="form-group">
                              <Form.Label>{t("Address")}</Form.Label>
                              <Form.Control
                                  type="text"
                                  name="Address"
                                  placeholder={t("Your Address")}
                                  value={me?.university}
                                  onChange={handleInputChange}
                              />
                          </Form.Group>
                        </div>}
                      </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button className="edit" type="submit">
                              Edit
                            </button>
                        </div>
                    </Form>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="profile-tab">
                <h2>History</h2>
                <div className="">
                  <div>
                  {order.map((x, index) => {
                    <div className="history" key={index}>
                      <div className="d-flex align-items-center">
                        <img src={Img1} alt="lab-logo"/>
                        <div>
                          <h3>#155151</h3>
                          <h4>Lab name</h4>
                          <p>address : Lorem ipsum dolor sit amet consectetur nunc nunc </p>
                        </div>
                      </div>
                      <span>15/5/2024</span>
                    </div>
                  })}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="profile-tab">
                <h2>My Labs</h2>
                <div className="labs pt-3 en">
                  <div className="container">
                    <div className="row">
                      {lab.map((x, index) => (
                        <div className="col-lg-4 col-md-6" key={index}>
                          {/* <div className="card-lab en">
                              <div className="rate">
                                {x.rate}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="15"
                                  viewBox="0 0 14 15"
                                  fill="none"
                                >
                                  <path
                                    d="M6.60697 0.756065C6.76774 0.414645 7.23226 0.414645 7.39303 0.756065L9.21661 4.62869C9.28045 4.76427 9.40386 4.85824 9.54661 4.87998L13.6243 5.50099C13.9838 5.55574 14.1273 6.01876 13.8672 6.28452L10.9166 9.29894C10.8133 9.40447 10.7661 9.55652 10.7905 9.70554L11.487 13.962C11.5485 14.3372 11.1727 14.6234 10.8511 14.4462L7.20395 12.4366C7.07627 12.3662 6.92373 12.3662 6.79605 12.4366L3.14889 14.4462C2.82735 14.6234 2.45154 14.3372 2.51295 13.962L3.2095 9.70553C3.23388 9.55652 3.18675 9.40447 3.08345 9.29894L0.132837 6.28452C-0.127295 6.01876 0.0162496 5.55574 0.375744 5.50099L4.45339 4.87998C4.59614 4.85824 4.71955 4.76427 4.78339 4.62869L6.60697 0.756065Z"
                                    fill="#EDF051"
                                  />
                                </svg>
                              </div>
                            <Link className="">
                              <img src={x.src} alt={x.name} />
                              <div className="p-3">
                                <h3>{x.name}</h3>
                                <p>{x.Services} Services</p>
                              </div>
                            </Link>
                          </div> */}
                        </div>
                      ))}
                      <div className="text-center mt-5">
                        <button className="load-more">Load more</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

export default Profile;
