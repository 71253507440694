import React, { useEffect, useState } from "react";
import Img8 from "../Assets/Images/Rectangle 29848.png";
import Img9 from "../Assets/Images/Vector.svg";
import Img1 from "../Assets/Images/Rectangle 145.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import Partner from "./Partner";
import Background from '../Assets/Images/7cc559aefa.jpg';
import parse from "html-react-parser";
import axios from "axios";
import Swal from "sweetalert2";

function About() {
  let [about, setAbout] = useState([]);
  const getAbout = async () => {
    try {
      const response = await axios.get(
        `https://dentotools.addictaco.com/api/v1/details`,
        {
          headers: {
            Accept: ["application/json", "application/json"],
            "api-key": "kiOcmnraXiUZ72IFPbPMfKl6DmgRceFCaDgBBYdxxQ6ob",
            "Accept-Language": "en",
            "Content-Type": "application/json",
          },
        }
      );
      setAbout(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

    const value = "aboutus";
    const filtered = about.filter((about) => about.type.includes(value));

    const value1 = "mission and vission";
    const mission = about.filter((about) => about.type.includes(value1));

    const value2 = "why choose us?";
    const choose = about.filter((about) => about.type.includes(value2));


  useEffect(() => {
    getAbout();
    // document.body.classList.add('pro-nav');
    document.body.classList.remove("join-nav");
    document.body.classList.remove("active-nav");
  }, []);
  return (
    <div className="main">
      <div
        className="hero-about en"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          {filtered.map((user) => (
            <div key={user.id}>
              <h1>{user.title}</h1>
              <p>{parse(`${user?.body}`)}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mission en">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Mission & Vision</h1>
            </div>
            {mission.map((user) => (
              <div className="col-lg-4 col-md-6" key={user.id}>
                <div className="vision">
                  <h2>{user.id}</h2>
                  <div>
                    <h3>{user.title}</h3>
                    <p>{parse(`${user?.body}`)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Partner />

      <div className="choose en">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span>Our Features</span>
              <h2 className="main-heading">Why Choose Us?</h2>
              <p className="main-description">
                Lorem ipsum dolor sit amet consectetur adipiscing.
              </p>
            </div>
            <div className="col-lg-7">
              <div>
                {choose.map((user) => (
                  <div className="benefit" key={user.id}>
                    <img src={Img9} alt="Benefit" />
                    <h3>{user.title}</h3>
                    <p>{parse(`${user?.body}`)}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="choose-img">
                <img src={Img1} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact en">
        <div className="container">
          <div className="contact-card">
            <div className="contact-layout">
              <h2>
                For any questions or concerns <br /> don't hesitate to contact
                us.
              </h2>
              <Link to="/Contact" className="">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
